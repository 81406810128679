.portfolio-page {
    padding-left: 100px;
    padding-right: 50px;
    width: calc(100% - 150px);
    position: initial;
    height: 100%;
    overflow: auto;

    h1.page-title {
        margin-left: 100px;
        margin-top: 100px;
    }

    .images-container {
        display: flex;
        gap: 15px;
        flex-wrap: wrap;
        padding-bottom: 100px;
    }

    .image-box {
        position: relative;
        flex: 1 1 20%;
        height: 400px;
        overflow: hidden;
        border-radius: 10px;
        max-width: calc(25% - 10px);

        .portfolio-image {
            position: absolute;
            z-index: 2;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .content {
            position: absolute;
            width: 100%;
            z-index: 3;
            padding: 10px 20px;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            background: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0.2) 0,
                rgba(0, 0, 0, 1)
            );
            bottom: -70px;
        }

        .title {
            margin-bottom: 0;
            margin-top: 0;
            color: #fff;
            font-size: 24px;
            font-weight: 500;
            line-height: 24px;
        }

        .description {
            font-size: 14px;
            margin-bottom: 5px;
            color: #fff;
            font-weight: 700;
        }

        .btn {
            margin-top: 30px;
            margin-bottom: 10px;
            padding: 0 23px;
            height: 40px;
            line-height: 34px;
            border: 2px solid #ffd700;
            border-radius: 4px;
            font-size: 14px;
            color: #fff;
            background: transparent;
            text-transform: uppercase;
            font-weight: 700;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            cursor: pointer;
        }

        .btn:hover {
            transform: translateY(-3px);
            background: #ffd700;
        }

        &:after {
            content: "";
            background: linear-gradient(180deg, #ffd700, #000);
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            opacity: 0;
        }

        &:hover:after {
            opacity: 0.85;
        }

        &:hover .content {
            bottom: 0;
            background: transparent;
        }
    }
}

@media screen and (min-width: 768px) {
    .container-fluid {
        display: flex;
        flex-wrap: wrap;
        object-fit: cover;
        margin: 1rem auto;;
    }

    .container-fluid img {
        width: 400px;
        object-fit:  cover;
        margin: 1rem;
        height: 400px;;
    }
}
.img-container {
  position: relative;
  display: inline-block;
}

.btns {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.btn {
  display: block;
  margin: 5px 0;
}

  .img-container {
    position: relative;
    display: inline-block;
  }
  .btns {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .img-container:hover .btns {
    display: flex;
  }
  .btn {
    background-color: black;
    color: white;
    font-size: 14px;
    padding: 8px 20px;
    border-radius: 50px;
    margin-right: 10px;
    cursor: pointer;
  }

  .info-btn {
    background-color: transparent;
    border: 1px solid white;
    color: white;
    font-size: 14px;
    padding: 8px 20px;
    border-radius: 50px;
    cursor: pointer;
  }
  .container-fluid {
    display: flex;
    flex-wrap: wrap;
  }

  @media (max-width: 475px) {
   
  .container-fluid {
  display: flex;
  flex-wrap: wrap;
  
  .img-container {
    width: 100%;
    
    @media (max-width: 475px) {
      width: auto;
    }
    
    img {
      width: 100%;
      height: auto;
    }
    
    .btns {
      display: flex;
      justify-content: center;
      margin-top: 1rem;
    }
  }
}
.img-container:hover .btn {
  display: block;
  cursor: pointer;
}
.img-container {
  position: relative;
  display: inline-block;
}
.btns {
  display: flex;
  flex-direction: column-reverse;;
  justify-content: flex-center;
  align-items: center;
}
.img-container:hover .btns {
  display: flex;
}
}
.container-fluid {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  justify-content: space-evenly;
  background-color: #ffd700;
}